@import 'src/styles/variables';

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: $ht-tablet-width) {
    flex-direction: row;
  }
}

.signContainer {
  transform: rotate(-7deg);
  position: relative;
  z-index: 2;
  margin: 100px 0 50px;
  max-width: 300px;
  text-align: center;
  font-size: 5.5px;

  @media (min-width: $ht-desktop-width) {
    margin: 150px 0 100px;
    max-width: 575px;
    font-size: 10px;
  }
}

.signImg {
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  bottom: -3em;
}

.signTextContainer {
  position: relative;
  display: inline-block;
  min-width: 270px;
  padding: 2.5em;

  @media (min-width: $ht-desktop-width) {
    min-width: 480px;
  }

  .code {
    color: $ht-cool-grey-tint-d2;
    font-size: 7em;
    line-height: 0.9;
    text-transform: uppercase;
    span {
      font-size: 2em;
    }
  }
  .errorText {
    color: $ht-vibrant-red;
    margin: 5px auto;
    font-size: 3em;
    line-height: 1;
  }
  .takeMeHome {
    font-weight: $ht-font-weight-bold;
    font-size: 3.2em;
  }
}

.servicesContainer {
  max-width: 550px;
  padding: 0 20px;

  h3,
  h5 {
    font-weight: $ht-font-weight-medium;
  }

  h3 {
    margin-bottom: 30px;
  }

  li {
    margin-bottom: 15px;
  }
}
